import { useCallback, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";

import {
  MultiSearchWithTermList,
  MultiTermSearchType,
} from "../../types/common";

import SearchChipList from "./SearchChipList";

function MultiSearchWithTerm<T extends string>({
  termSearchTypeOptionList,
  multiSearchWithTermList,
  setMultiSearchWithTermList,
  resetMultiSearchWithTermList,
  isJustifyContentFlexStart,
}: {
  termSearchTypeOptionList: MultiTermSearchType<T>[];
  multiSearchWithTermList: MultiSearchWithTermList<T>[];
  setMultiSearchWithTermList: (value: MultiSearchWithTermList<T>[]) => void;
  // jotai 리셋 함수의 타입이 () => unknown;
  resetMultiSearchWithTermList: () => unknown;
  // 필터의 위치를 오른쪽으로 변경 기본값은 왼쪽
  isJustifyContentFlexStart?: boolean;
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState(
    termSearchTypeOptionList[0].value
  );

  const handleSearchWithTermUpdate = useCallback(() => {
    const hasSameSearchType = Boolean(
      multiSearchWithTermList.find((item) => {
        return item.searchType === searchType;
      })
    );

    // 이미 검색을 했던 항목일 경우 이전 검색를 제거
    if (hasSameSearchType) {
      const filteredMultiSearchWithTermList = multiSearchWithTermList.filter(
        (item) => {
          return item.searchType !== searchType;
        }
      );
      setMultiSearchWithTermList([
        ...filteredMultiSearchWithTermList,
        { searchTerm, searchType },
      ]);
    } else {
      setMultiSearchWithTermList([
        ...multiSearchWithTermList,
        { searchTerm, searchType },
      ]);
    }

    setSearchTerm("");
    setSearchType(termSearchTypeOptionList[0].value);
  }, [
    multiSearchWithTermList,
    searchTerm,
    searchType,
    setMultiSearchWithTermList,
    termSearchTypeOptionList,
  ]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: isJustifyContentFlexStart ? "flex-start" : "flex-end",
        }}
      >
        <FormControl
          className="search-type"
          variant="outlined"
          sx={{ mr: 0.5 }}
        >
          <InputLabel id="multi-search-term-select-label">검색 대상</InputLabel>

          <Select
            labelId="multi-search-term-select-label"
            id="multi-search-term-select"
            value={searchType || ""}
            label="검색 대상"
            onChange={(event) => {
              setSearchType(event.target.value as T);
            }}
            size="small"
          >
            {termSearchTypeOptionList.map((optionItem, index) => (
              <MenuItem value={optionItem.value} key={index}>
                {optionItem.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box>
          <OutlinedInput
            size="small"
            placeholder="검색어"
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                handleSearchWithTermUpdate();
              }
            }}
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="update-icon-button"
                  onClick={handleSearchWithTermUpdate}
                  edge="end"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </Box>
      </Box>

      {multiSearchWithTermList.length ? (
        <SearchChipList
          termSearchTypeOptionList={termSearchTypeOptionList}
          multiSearchWithTermList={multiSearchWithTermList}
          setMultiSearchWithTermList={setMultiSearchWithTermList}
          resetMultiSearchWithTermList={resetMultiSearchWithTermList}
        />
      ) : null}
    </Box>
  );
}

export default MultiSearchWithTerm;
