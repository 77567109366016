import { createContext } from "react";
import { AlertColor } from "@mui/material";

export interface SnackbarContextType {
  handleSnackbarOpen: (message: string, color?: AlertColor) => void;
}

export const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);
