import { useCallback } from "react";

import ADMIN_TEAM_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_TEAM_QUERY";

import { DetailType } from "../../index";

type AdminManagerType = "forwarding" | "fullfillment" | "exportForwarding";

export default function useHandleManager({
  type,
  teamId,
  id,
  refetchDetailData,
  setShowsSuccessSnackBar,
  setShowsErrorSnackBar,
}: {
  type: DetailType;
  teamId: number;
  id: number;
  refetchDetailData: () => void;
  setShowsSuccessSnackBar: (val: boolean) => void;
  setShowsErrorSnackBar: (val: boolean) => void;
}) {
  const { mutate: assignTeamManager } = ADMIN_TEAM_QUERY.useAssignTeamManager(
    type === "user" ? teamId : id
  );

  const handleManagerAssign = useCallback(
    (managerId: number, managerType: AdminManagerType) => {
      if (!managerId) return;

      const targetId = type === "team" ? id : teamId;

      const managerAssignment = {
        teamId: targetId,
        fulfillmentManagerId:
          managerType === "fullfillment" ? managerId : undefined,
        forwardingManagerId:
          managerType === "forwarding" ? managerId : undefined,
        exportForwardingManagerId:
          managerType === "exportForwarding" ? managerId : undefined,
      };

      assignTeamManager(managerAssignment, {
        onSuccess: () => {
          setShowsSuccessSnackBar(true);
          refetchDetailData();
        },
        onError: () => setShowsErrorSnackBar(true),
      });
    },
    [
      assignTeamManager,
      id,
      refetchDetailData,
      setShowsErrorSnackBar,
      setShowsSuccessSnackBar,
      teamId,
      type,
    ]
  );

  return {
    handleManagerAssign,
  };
}
